
.flip-card {
    background-color: transparent;
    width: 200px;
    height: 200px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #8a96cc;
    border-radius: 20px;
    background-color: rgba(73, 80, 86, 0.54);
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 150px;
  }
  
  /* Style the back side */
  .flip-card-back {
    color: white;
    transform: rotateY(180deg);
    font-size: 1.5em;
    font-weight: bold;

    h1 {
      font-size: 2.0em;
    }
  }

  .learn-more {
    color: #ffd700;
  }
  