
.nav-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
    right: 0px;
    background: #181818;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.logo {
    margin-top: 15px;

    a {
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        height: 51px;
        text-align: center;
        text-decoration: none;
        width: 100%;
        color: rgb(173, 216, 230);

        &:hover {
            color: #ffd700;
            svg {
                color: #ffd700; 
            }
        } 
    }    
}

nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    a {
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        text-decoration: none;
        width: 100%;

        i {
            transition: all 0.3s ease-out;
        }
    
        &:hover {
            color: #ffd700;

            svg {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }

        &:after {
            content: "";
            font-size: 9px;
            letter-spacing: 2px;
            position: relative;
            top: -50px;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0;
            transition: all 0.3s ease-out;
        }

        &:first-child {
            &::after {
                content: 'HOME';
            }
        }
    }

    a.about-link {
        &:after {
            content: 'ABOUT';
        }
    }

    a.contact-link {
        &:after {
            content: 'CONTACT';
        }
    }

    a.active {
        svg {
            color: #ffd700;
        }
    }

}

.websites {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-bottom: 15px;
    
    a {
        padding: 7px 0;
        display: block;
        font-size: 20px;
        line-height: 16px;
        width: 100%;

        &:hover svg {
            color: #ffd700;
        } 
    }
}

@media (max-width: 600px) {

    .nav-bar {
        nav {
            a {
                svg{
                    opacity: 1;
                }

                &::after {
                    opacity: 0;
                }
            }
        }
    }
}