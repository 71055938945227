.home-page {

    .text-zone {
        max-height: 90%;
        margin: 30px 30px;
    }

    h1 {
        color: #fff;
        font-size: 53px;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: relative;
            top: -40px;
            left: -10px;
            opacity: 0.6;
        }

        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: relative;
            top: 5px;
            left: 10px;
            opacity: 0.6;
            animation: fadeIn 1s 1.7s backwards;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #ffd700;
        font-size: 13px;
        text-align: center;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin: 30px 30px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: no-wrap;

        &:hover {
            background: #ffd700;
            color: #333;
        }
    }

    .me-container {
        img {
            height: 700px;
            width: 400px;
        }
    }
}

@media (max-width: 600px) {
    .right-side-container {
        display: none;
    }

    .home-page {
        .text-zone {
            width: 80%;
            margin: 40px 10px;

            h1 {
                font-size: 32px;
            }
            
            h2 {
                text-align: center;
            }
        }
    }
}