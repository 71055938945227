.tech-stack {
    h1 {
        //font
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #ffd700;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
    }

    .tech-stack-grid {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        width: 850px;
    }
}