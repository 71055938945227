.app {
    display: grid;
    grid-template-columns: 80px auto;
    width: 100%;
    min-height: 100%;
}

.page {
    // grid
    display: grid;
    grid-template-rows: 30px auto 30px;

    // sizing
    width: 100%;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    font-size: 18px;
    font-family: 'La Belle Aurore';
    margin-left: 5px;
}

.container {

    //flex
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    // block
    width: 100%;
    min-height: 566px;
    margin: 0 auto;
    margin-bottom: 20px;

    // animation
    opacity: 0;
    will-change: contents;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.about-page,
.contact-page {
    .text-zone {
        width: 35%;
        margin: 40px 40px;

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #ffd700;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;
            
            &::before{
                content: '<h1>';
                font-family: 'La Belle Aurore';
                font-size: 18px;
                position: absolute;
                margin-top: -10px;
                left: -10px;
                opacity: 0.6;
                line-height: 18px;
            }

            &::after{
                content: '</h1>';
                font-family: 'La Belle Aurore';
                font-size: 18px;
                position: absolute;
                margin-top: -10px;
                left: -10px;
                bottom: -20px;
                opacity: 0.6;
                line-height: 18px;
            }
        }

        p {
            font-size: 17px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }
    }

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}

@media (max-width: 600px) {

    .app {
        display: grid;
        grid-template-columns: 40px auto;
        width: 100%;
        min-height: 100%;
    }

    .right-side-container {
        display: none;
    }

    .about-page,
    .contact-page {
        .text-zone {
            width: 80%;
            margin: 40px 10px;
        }
    }
}